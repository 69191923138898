import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const Uitstel = () => (
  <section className='page-section' id='uitstel'>
    <Container>
      <Row>
        <Col lg={12} className='text-center'>
          <h2 className='section-heading'>Uitstel GigantenTrail 19 april 2020</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <p className='text-justify'>
            Door de nieuwe richtlijnen omtrent het coronavirus (COVID-19), heeft de organisatie zojuist - maandag 23
            maart 19.30 uur - besloten om de Giganten Trail die gepland staat op 19 april te verplaatsen naar een datum
            later in dit jaar of in het voorjaar van 2021.
          </p>
          <p className='text-justify'>
            Personen die reeds ingeschreven hebben kunnen zelfs beslissen of ze de inschrijvingen willen behouden voor
            de volgende editie of dat ze hun inschrijfgeld terug willen krijgen via een tegenboeking (al dan niet
            inclusief het extra gesponsorde bedrag). Deze personen zullen per e-mail instructies ontvangen hoe ze hun
            voorkeur kunnen aangeven.
          </p>
          <p className='text-justify'>
            De datum voor de volgende editie van de Giganten Trail zal bekend gemaakt worden wanneer er meer
            duidelijkheid is over de situatie rondom het coronavirus (COVID-19). Mocht je extra informatie willen kan je
            de organisatie altijd bereiken via{' '}
            <a href='https://www.facebook.com/Giganten-Trail-265367004389192'>Facebook</a> of via &nbsp;
            <a href='trail2020@gigantentrail.nl'>trail2020@gigantentrail.nl</a>
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);
