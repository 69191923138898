import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const NieuwsSection = () => (
  <section className='page-section' id='nieuws'>
    <Container>
      <Row>
        <Col lg={12} className='text-center'>
          <h2 className='section-heading'>Nieuws</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className='news-item'>
            <img style={{ objectFit: 'scale-down', width: '100%' }} src='img/results.jpg' alt='Resultaten' />
            <div className='body'>
              <div className='date'>2019.03.24</div>
              <h2>Uitslag - 2019</h2>
              <a href='GigantenTrailUitslag2019.pdf'>Download</a>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className='news-item'>
            <img style={{ objectFit: 'scale-down', width: '100%' }} src='img/bos.jpg' alt='Resultaten' />
            <div className='body'>
              <div className='date'>2019.03.14</div>
              <h2>Laatste informatie</h2>
              <a href='/nieuws/2019-03-14-laatste-info'>Lees meer</a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
