import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const InschrijvenSection = () => (
  <section className='page-section' id='inschrijven'>
    <Container>
      <Row>
        <Col lg={12} className='text-center'>
          <h2 className='section-heading'>Inschrijven</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <p className='text-justify'>
            De <a href='https://inschrijven.nl/form/2020041951033-nl'>voor inschrijvingen</a> zijn geopend en verlopen
            via <a href='https://inschrijven.nl/form/2020041951033-nl'>inschrijven.nl</a>. Like de{' '}
            <a href='https://www.facebook.com/Giganten-Trail-265367004389192'>GigantenTrail facbook pagina</a> om op de
            hoogte blijven van alle nieuws gerelateerd met de GigantenTrail.
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);
