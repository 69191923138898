export const items = [
  {
    date: '2019.03.14',
    name: '2019-03-14-laatste-info',
    img: '/img/bos.jpg',
    imgPage: '/img/bos-full.jpg',
    title: 'Laatste informatie',
    body: `
    <p>Zondag is het dan zover dan gaat om 10:00 onze mooie trail van start.
    Het weer is nog even afwachten, maar met wat geluk blijft het droog en krijgen we nog een zonnetje te zien.</p>
    <h3>Ophalen startnummers en secretariaat</h3>
    <p>Secretariaat, start en finish zijn allemaal aan de De Nieuwe Drenck, Zuidgeest 17,
      4625 DG Bergen op Zoom</p>
    <p>Gelieve uw startnummer te vermelden bij afhaling van uw startnummer aan de inschrijvingsstand. Dit kan op zondagochtend vanaf 9u30.</p>

    <h3>Parking</h3>
    <p>Parkeren is langs de kant van de weg(Zuidgeest).Gelieve de instructies van de parkeerwachters op te volgen.</p>

    <h3>Programma</h3>
    <p>Zondag 17 maart 2019</p>

    <pre>10:00 - Kids run(gratis)
10:30 - 5 km Trail
10:30 - 20 km Trail
11:00 - 15 km Trail
13:00 - Prijsuitreiking voor de 20km en loterij</pre>
`,
  },
];
