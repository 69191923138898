import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { items } from './NieuwsItems';
import { Header } from '../components/Header';

export const NieuwsItem = () => {
  const { name } = useParams();
  return items
    .filter(item => item.name === name)
    .map(item => (
      <>
        <Header />

        <img src={item.imgPage} alt={item.title} className='w-100 d-none d-lg-block' />

        <section className='page-section news-item-section'>
          <Container>
            <div className='date'>{item.date}</div>
            <h2 className='section-heading'>{item.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: item.body }} />
          </Container>
        </section>
      </>
    ));
};
