import React, { useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';

export const Header = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Navbar variant='light' expand='lg' fixed='top' bg='light' id='mainNav' expanded={expanded}>
      <Container>
        <Link className='js-scroll-trigger nav-link' to='/#page-top' smooth>
          <Navbar.Brand className='js-scroll-trigger'>Giganten Trail></Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls='basic-navbar-nav' onClick={() => setExpanded(expanded ? false : 'expanded')} />
        <Navbar.Collapse id='navbarResponsive'>
          <Nav className='ml-auto text-uppercase'>
            <Link className='js-scroll-trigger nav-link' to='/#afstanden' smooth onClick={() => setExpanded(false)}>
              Afstanden
            </Link>
            <Link className='js-scroll-trigger nav-link' to='/#inschrijven' smooth onClick={() => setExpanded(false)}>
              Inschrijven
            </Link>
            <Link className='js-scroll-trigger nav-link' to='/#nieuws' smooth onClick={() => setExpanded(false)}>
              Nieuws
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
