import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

export const AfstandenSection = () => (
  <section className='page-section' id='afstanden'>
    <Container>
      <Row>
        <Col lg={12} className='text-center'>
          <h2 className='section-heading'>Kies je afstand</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <p className='text-justify'>
            Neem deel aan deze mooie trail over singletracks, De Brabantse Wal en zandduinen. Om de trail toegankelijk
            te maken van jong tot oud, van beginnende trailer tot competitieve trailer, zijn er afstand van 5, 15 of 20
            km en een kidsrun van 1km. Voor alle afstanden zal er een tijdwaarneming gebeuren en een uitslag opgemaakt
            worden. Om het nog aantrekkelijke te maken voor de competitieve trailer, zullen er voor de eerste drie
            mannen en vrouwen op de hoofdafstand van 20km mooie prijzen voorzien worden.
          </p>
          <p className='text-justify'>
            Net zoals bij de vorige editie gaat dit jaar ook de gehele opbrengst van het event naar{' '}
            <a href='https://www.roparun.nl/'>Stichting Roparun</a>. Het geld wat wordt opgehaald tijdens het evenement
            Roparun wordt door het bestuur van Stichting Roparun toegekend aan instellingen, goede doelen of projecten
            die bijdragen aan de missie van Roparun: ”Leven toevoegen aan de dagen, waar vaak geen dagen meer kunnen
            worden toegevoegd aan het leven”
          </p>
          <p className='text-justify'>
            Deze trail wordt georganiseerd door Roparun <a href='https://teamnnn.nl/'>team NNN</a> uit Bergen Op Zoom.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Card className='card-event' mb={5} lg={0}>
            <Card.Title className='text-uppercase text-center'>5 km</Card.Title>
            <Card.Subtitle className='text-center price'>voorinschrijving 7 euro, na 10 euro</Card.Subtitle>
            <Card.Body>
              <img
                style={{ objectFit: 'scale-down', width: '100%' }}
                src='img/5K.jpg'
                alt='kid running in the forest'
              />
              <p className='text-justify'>
                De 5 km trail is een mooie trail om kennis te maken met het traillopen. Zoals alle andere afstanden zal
                de 5 km trail geen verharde stukken weg bevatten. Het grootste gedeelte van deze trail zal over
                singletracks door de bossen gaan. Om het toch iets uitdagender te maken zullen er ook enkele stroken
                over de zandduinen in zitten.
              </p>
              <p className='text-justify'>Op de 5 km trail zal er geen bevoorradingspost zijn.</p>
              <p className='text-justify'>
                Online inschrijven via <a href='https://inschrijven.nl/form/2020041951033-nl'>inschrijven.nl</a>.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className='card-event' mb={5} lg={0}>
            <Card.Title className='text-uppercase text-center'>15 km</Card.Title>
            <Card.Subtitle className='text-center price'>voorinschrijving 9 euro, na 12 euro</Card.Subtitle>
            <Card.Body>
              <img
                style={{ objectFit: 'scale-down', width: '100%' }}
                src='img/15K.jpg'
                alt='kid running in the forest'
              />
              <p className='text-justify'>
                De 15 km trail is een mooie trail voor de loper die 20 km toch net iets te ver vindt, maar toch van een
                uitdaging houdt. Deze trail bevat alle elementen die de Brabantse Wal zo mooi maken: singletracks door
                de bossen, zandduinen, heide vlakten, en de vele korte maar stijle klimmetjes. Kortom het is een mooie
                en uitdagende trail.
              </p>
              <p className='text-justify'>Op de 15 km trail is er één bevoorradingspost.</p>
              <p className='text-justify'>
                Online inschrijven via <a href='https://inschrijven.nl/form/2020041951033-nl'>inschrijven.nl</a>.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className='card-event' mb={5} lg={0}>
            <Card.Title className='text-uppercase text-center'>20 km</Card.Title>
            <Card.Subtitle className='text-center price'>voorinschrijving 10 euro, na 14 euro</Card.Subtitle>
            <Card.Body>
              <img
                style={{ objectFit: 'scale-down', width: '100%' }}
                src='img/20K.jpg'
                alt='kid running in the forest'
              />
              <p className='text-justify'>
                De 20 km trail zal de hoofdafstand van de Gigantent Trail zijn. Deze afstand bevat, net zoals de 15 km,
                alle elementen die de Brabantse Wal zo mooi maken: singletracks door de bossen, zandduinen, heide
                vlakten, en de vele korte maar stijle klimmetjes.
              </p>
              <p className='text-justify'>
                De eerste drie mannen en vrouwen op deze afstand zullen een mooie prijs krijgen.
              </p>
              <p className='text-justify'>Op de 20 km trail zijn er twee bevoorradingsposten.</p>
              <p className='text-justify'>
                Online inschrijven via <a href='https://inschrijven.nl/form/2020041951033-nl'>inschrijven.nl</a>.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className='card-event' mb={5} lg={0}>
            <Card.Title className='text-uppercase text-center'>kidsrun</Card.Title>
            <Card.Subtitle className='text-center price'>gratis</Card.Subtitle>
            <Card.Body>
              <img
                style={{ objectFit: 'scale-down', width: '100%' }}
                src='img/kids.jpg'
                alt='kid running in the forest'
              />
              <p className='text-justify'>
                Voor de kinderen is er een echte kids trail over een leuk parcours in de bossen rond de{' '}
                <a href='http://www.denieuwedrenck.nl/'>De Nieuwe Drenck</a>.
              </p>
              <p className='text-justify'>
                De kinderen krijgen iets lekkers en wat te drinken nadat ze gefinisht zijn.
              </p>
              <p className='text-justify'>Kosteloos inschrijven kan op de dag zelf bij de Nieuwe Drenck vanaf 9:30.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </section>
);
