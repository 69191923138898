import React from 'react';
// import logo from './logo.svg';
import './App.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Uitstel } from './sections/Uitstel';
import { AfstandenSection } from './sections/AfstandenSection';
import { NieuwsSection } from './sections/NieuwsSection';
import { InschrijvenSection } from './sections/InschrijvenSection';
import { NieuwsItem } from './pages/NieuwsItem';
import { Header } from './components/Header';
import { GAListener } from './components/GAListener';

function App() {
  return (
    <Router>
      <GAListener trackingId='UA-158871244-1'>
        <Switch>
          <Route exact path='/'>
            <Header />

            {/* Header */}
            <header className='masthead'>
              <div className='container'>
                <div className='intro-text'>
                  <div className='intro-lead-in'>19 april 2020</div>
                  <div className='intro-heading text-uppercase'>Giganten Trail</div>
                  <Link className='btn btn-primary btn-xl text-uppercase js-scroll-trigger' to='/#afstanden' smooth>
                    Afstanden
                  </Link>
                </div>
              </div>
            </header>

            <Uitstel />

            <AfstandenSection />

            <InschrijvenSection />

            <NieuwsSection />

            {/* Contact */}
            <section className='page-section' id='contact'></section>

            {/* Footer */}
            <footer className='footer'>
              <Container>
                <Row className='align-items-center'>
                  <Col md={4}>
                    <span className='copyright'>Copyright &copy; Giganten Trail 2019</span>
                  </Col>
                  <Col md={4}>
                    <ul className='list-inline social-buttons'>
                      <li className='list-inline-item'>
                        <a href='https://www.facebook.com/Giganten-Trail-265367004389192'>
                          <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='https://twitter.com/gigantentrail'>
                          <FontAwesomeIcon icon={faTwitter} />
                        </a>
                      </li>
                      <li className='list-inline-item'>
                        <a href='https://www.instagram.com/gigantentrail/'>
                          <FontAwesomeIcon icon={faInstagram} />
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </footer>
          </Route>
          <Route path='/resultaten/gigantentrail-2019'>
            <div>Results 2019</div>
          </Route>
          <Route path='/nieuws/:name'>
            <NieuwsItem />
          </Route>
        </Switch>
      </GAListener>
    </Router>
  );
}

export default App;
