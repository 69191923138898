import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router';

const sendPageView = location => {
  const trackLocation = location.pathname + location.hash;
  ReactGA.set({ page: trackLocation });
  ReactGA.pageview(trackLocation);
  console.info('GA|Pageview Sent: ', trackLocation);
};

export const GAListener = ({ children, trackingId }) => {
  const history = useHistory();
  useEffect(() => {
    const isGAEnabled = process.env.NODE_ENV === 'production';
    if (trackingId && isGAEnabled) {
      ReactGA.initialize(trackingId);
      sendPageView(history.location, 'REPLACE');
      return history.listen(sendPageView);
    }
  }, [history, trackingId]);

  return children;
};
